import React from "react";
import { useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

export default function Swipeable(props) {
  const navigate = useNavigate();
  const handlers = useSwipeable({
    onSwipedLeft: () => navigate(1),
    onSwipedRight: () => !props.backDisabled && navigate(-1),
  });

  const refPassthrough = (el) => {
    handlers.ref(el);
  };
  return <div ref={refPassthrough}>{props.children}</div>;
}
