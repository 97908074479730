import React from "react";
import RouterContainer from "./Router";
import { StoreProvider } from "easy-peasy";
import { store } from "./Redux/index";
import "./App.css";

export default function App() {
  return (
    <StoreProvider store={store}>
      <RouterContainer />
    </StoreProvider>
  );
}
