import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";

export default function LoadingPage(props) {
  const { delay = 500 } = props;
  const [ready, setReady] = useState(delay === 0);
  useEffect(() => {
    let timeout = null;
    if (!ready) {
      timeout = setTimeout(() => setReady(true), delay);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, []);
  if (!ready) return null;

  return (
    <div className="vw-100 vh-100 d-flex flex-column justify-content-center align-items-center">
      <FontAwesomeIcon icon={faCircleNotch} size="5x" className="fa-spin" />
       <h2 className="mt-3 black-text">{props.title || 'Loading ...'}</h2>
    </div>
  );
}
