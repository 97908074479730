import { createStore, action, persist, computed } from "easy-peasy";

const today = () => new Date().toDateString();

const defaultSession = {
  name: null,
  id: null,
  token: null,
  provider: null,
  tos: false,
};

export const defaultUser = {
  isActive: false,
  primaryName: "",
  primaryEmail: "",
  secondaryEmail: "",
  monthlyEmail: false,
  subscriptionLevel: 0,
  cusID: "",
  isGame: true,
  hideWelcome: false,
  friends: [],
  block: [],
  ignore: [],
  periodEnd: null,
  planId: null,
  allowFriends: true,
  allowInvitations: true,
  needsConfirm: false,
  lastActive: 0,
  lastActive2: 0,
  monthCnt: 0,
  startVideo: false,
};

export const store = createStore({
  session: persist({
    data: { ...defaultSession },
    isActive: false,
    setActive: action((state, payload) => {
      state.isActive = payload;
    }),
    update: action((state, payload) => {
      state.data = { ...state.data, ...payload };
    }),
    reset: action((state, payload = false) => {
      state.data = { ...defaultSession };
      state.isActive = false;
    })
  }),
  camera: {
    latex: [],
    raw: [],
    isReset: false,
    isAppend: false,
    setLatex: action((state, payload) => {
      const { latex_styled, latex_raw } = payload;
      state.latex = latex_styled;
      state.raw = state.isAppend ? [...state.raw, latex_raw] : [latex_raw];
    }),
    cancelReset: action((state) => {
      state.isReset = false;
    }),
    resetLatex: action((state) => {
      state.latex = [];
      state.raw = [];
      state.isAppend = false;
      state.isReset = true;
    }),
    setIsAppend: action((state, payload) => {
      state.latex = [];
      state.isAppend = payload;
    }),
    isProcessing: false,
    setProcessing: action((state, payload) => {
      state.isProcessing = payload;
    }),
  },
  editor: {
    latex: [],
    formulas: [],
    type: 0,
    warningModal: 0,
    overrideModal: 0,
    leaderboardModal: false,
    tutorialModal: false,
    historyModal: false,
    timeoutModal: false,
    supportModal: false,
    parentModal: false,
    videoData: false,
    emailModal: false,
    gameModal: false,
    isReset: false,
    hasCamera: true,
    lastHint: "",
    celebrating: false,
    hasAd: false,
    error: false,
    setError: action((state, payload) => {
      state.error = payload;
    }),
    update: action((state, { type, value }) => {
      state[type] = value;
    }),
    setCelebrating: action((state, payload) => {
      state.celebrating = payload;
    }),
    setLatex: action((state, { latex }) => {
      if (latex !== undefined && latex.length) {
        if (state.latex.includes(""))
          state.latex = [...state.latex.filter((l) => l.length), latex];
        else {
          state.formulas = [];
          state.latex = [latex];
        }
      }
    }),
    reset: action((state) => {
      state.latex = [];
      state.formulas = [];
    }),
  },
  lastChecked: persist({
    date: new Date(),
    isErr: false,
    isSame: false,
    prevWork: [],
    allPrevWork: [],
    allPrevWorkDate: today(),
    update: action((state) => {
      state.date = new Date();
    }),
    setIsErr: action((state, payload) => {
      state.isErr = payload;
    }),
    setPrevWork: action((state, payload) => {
      state.prevWork = payload;
    }),
    setAllPrevWork: action((state, payload) => {
      const todayDate = today();
      if (todayDate === state.allPrevWorkDate) {
        state.allPrevWork = [...state.allPrevWork, JSON.stringify(payload)];
        return;
      }
      state.allPrevWorkDate = todayDate;
      state.allPrevWork = [JSON.stringify(payload)];
    }),
    setIsSame: action((state, payload) => {
      state.isSame = payload;
    }),
  }),
  support: persist({
    status: false,
    isReset: false,
    data: {
      status: 0,
      from: "",
      to: "",
      origin: "",
      instructions: [],
    },
    steps: [],
    update: action((state, payload) => {
      state.status = !state.status;
      state.data = payload;
    }),
    reset: action((state, payload) => {
      state.isReset = payload;
    }),
    updateSteps: action((state, payload) => {
      state.steps = payload;
    }),
  }),
  user: {
    data: persist({ ...defaultUser }),
    isOverLimit: computed((state) => {
      const monthCnt = state.data.monthCnt;
      const subscriptionLevel = state.data.subscriptionLevel;
      return (
        state.isOverPictureLimit ||
        monthCnt > 1500 ||
        (subscriptionLevel === 1 && monthCnt > 500) ||
        (subscriptionLevel === 0 && monthCnt > 10)
      );
    }),
    isOverPictureLimit: false,
    consent: persist({ status: false }),
    parent: persist({
      students: [],
    }),
    updateUser: action((state, payload) => {
      state.data = { ...state.data, ...payload };
    }),
    toogleGameMode: action((state) => {
      state.data.isGame = !state.data.isGame;
    }),
    toogleMonthlyEmail: action((state) => {
      state.data.monthlyEmail = !state.data.monthlyEmail;
    }),
    updateParent: action((state, payload) => {
      if ("students" in payload) state.parent.students = [...payload.students];
      else state.parent.students = [...payload];
    }),
    updateData: action((state, { type, value }) => {
      state.data[type] = value;
    }),
    incrementCnt: action((state) => {
      state.data.monthCnt = state.data.monthCnt + 1;
    }),
    resetData: action((state) => {
      state.data = { ...defaultUser };
      state.parent = { students: [] };
    }),
    updateConsent: action((state, payload) => {
      state.consent.status = payload;
    }),
    updatePicture: action((state, payload) => {
      state.isOverPictureLimit = payload;
    }),
  },
  legal: {
    privacy: false,
    terms: false,
    update: action((state, { type, value }) => {
      state[type] = value;
    }),
  },
  error: {
    type: null,
    update: action((state, payload) => {
      state.type = payload;
    }),
  },
});
