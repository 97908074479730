import React, { Suspense, lazy, useEffect } from "react";
import { BrowserRouter, Navigate, Outlet, Route } from "react-router-dom";
import SlideRoutes from "react-slide-routes";
import ScrollToTop from "./ScrollToTop";
import LoadingPage from "./LoadingPage";
import Swipeable from "./swipeable";
import { Device } from "@capacitor/device";
import { useStoreActions, useStoreState } from "easy-peasy";

const SupportPage = lazy(() => import("../Settings/Support"));
const LandingPage = lazy(() => import("../Landing"));
const FeedbackPage = lazy(() => import("../Settings/Feedback"));
const TutorialPage = lazy(() => import("../Tutorial"));
const LegalPage = lazy(() => import("../Legal"));
const AccountPage = lazy(() => import("../Settings/Account"));
const CookiePage = lazy(() => import("../Legal/Cookie"));
const RequireAuth = lazy(() => import("./auth"));
const RouterOverlay = lazy(() => import("./LoadingOverlay"));
const SettingsPage = lazy(() => import("../Settings"));
const Terms = lazy(() => import("../Legal/Terms"));
const Privacy = lazy(() => import("../Legal/Privacy"));
const FAQPage = lazy(() => import("../FAQ"));
const SocialMediaPage = lazy(() => import("../Settings/SocialMedia"));
const LogoutPage = lazy(() => import("../Authentication/Logout"));
// const AppLoginPage = lazy(() =>
//   import("../Authentication/components/AppleApp")
// );
const EditorPage = lazy(() => import("../Editor"));
const LoginPage = lazy(() => import("../Authentication"));
const AlertModal = lazy(() => import("./alert"));
const CameraPage = lazy(() => import("../Camera"));

const pathListOrder = [
  "/Login",
  "/Legal",
  "/Tutorial",
  "/Editor",
  "/Settings",
  "/Settings/Account",
  "/Settings/Support",
  "/Settings/Feedback",
  "/Logout",
  "/FAQ",
  "/Social",
  "/Terms",
  "/Privacy",
  "/Logout",
  "/",
];

export default function Router() {
  const isIOS = useStoreState((state) => state.session.data.isIOS);
  const update = useStoreActions((state) => state.session.update);
  useEffect(() => {
    async function getPlatform() {
      const { platform } = await Device.getInfo();
      update({ isIOS: platform  === "ios" });
    }
    getPlatform();
    // eslint-disable-next-line
  }, []);

  return (
    <BrowserRouter>
      <ScrollToTop>
        <Suspense fallback={<LoadingPage />}>
          <SlideRoutes duration={650} pathList={pathListOrder}>
            <Route
              path="/"
              element={
                isIOS? (
                  <Navigate to="/Login" replace />
                ) : (
                  <LandingPage />
                )
              }
            />
            <Route path="Landing" element={<LandingPage />} />
            <Route
              path="/Login"
              element={
                <RouterOverlay>
                  <LoginPage />
                  <AlertModal />
                </RouterOverlay>
              }
            />
            {/* <Route path="AppLogin" element={<AppLoginPage />} /> */}
            <Route
              path="FAQ"
              element={
                <Swipeable>
                  <FAQPage />
                </Swipeable>
              }
            />
            <Route
              path="Social"
              element={
                <Swipeable>
                  <SocialMediaPage />
                </Swipeable>
              }
            />
            <Route
              path="Terms"
              element={
                <Swipeable>
                  <Terms />
                </Swipeable>
              }
            />
            <Route
              path="Privacy"
              element={
                <Swipeable>
                  <Privacy />
                </Swipeable>
              }
            />
            <Route
              path="Cookie"
              element={
                <Swipeable>
                  <CookiePage />
                </Swipeable>
              }
            />
            <Route
              path="/Legal"
              element={
                <RequireAuth>
                  <LegalPage />
                </RequireAuth>
              }
            />
            <Route
              path="/Logout"
              element={
                <RouterOverlay>
                  <LogoutPage />
                </RouterOverlay>
              }
            />
            <Route
              path="/Editor"
              element={
                // <RequireAuth>
                  <Outlet />
                // </RequireAuth>
              }
            >
              <Route
                index
                element={
                  <RouterOverlay>
                    <EditorPage />
                    {/* <AlertModal /> */}
                  </RouterOverlay>
                }
              />
              <Route path="Camera" element={<CameraPage />} />
            </Route>
            <Route
              path="/Settings"
              element={
                <RequireAuth>
                  <Swipeable>
                    <Outlet />
                    <AlertModal />
                  </Swipeable>
                </RequireAuth>
              }
            >
              <Route index element={<SettingsPage />} />
              <Route path="Account" element={<AccountPage />} />
              <Route path="Support" element={<SupportPage />} />
              <Route path="Feedback" element={<FeedbackPage />} />
            </Route>
            <Route
              path="/Tutorial"
              element={
                <RequireAuth>
                  <TutorialPage />
                </RequireAuth>
              }
            />
            <Route path="*" element={<Navigate to={"/"} />} />
          </SlideRoutes>
        </Suspense>
      </ScrollToTop>
    </BrowserRouter>
  );
}
